<template>
  <KRow v-if="user" align="center" no-gutters>
    <KCol>
      <UserAvatar class="mr-1" size="32" :user="user" />
    </KCol>
    <KCol v-if="!mobile">
      <KText as="span" class="text-no-wrap" variant="body-2">
        {{ user.fullName }}
        <KText v-if="user.employeeId != null" as="span" variant="caption">
          ({{ user.employeeId }})
        </KText>
      </KText>
      <br />
      <KText
        as="span"
        class="text-medium-emphasis text-no-wrap"
        variant="caption"
      >
        {{ user.email }}
        <KChip
          v-if="user.userType === 'assistant'"
          color="accent"
          label
          size="x-small"
        >
          アシスタント
        </KChip>
      </KText>
    </KCol>
    <KCol>
      <KIcon end icon="$mdiMenuDown" />
    </KCol>

    <KMenu>
      <KList nav>
        <KListItem
          v-for="item in items"
          :key="item.url"
          density="compact"
          :prepend-icon="item.icon"
          :title="item.title"
          :to="localePath(item.url)"
        />

        <KDivider />

        <KListItem
          prepend-icon="$mdiArrowRight"
          :title="$t('ドロワー.ログアウト')"
          @click="logOut()"
        />
      </KList>
    </KMenu>
  </KRow>
</template>

<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'
import type { TranslateResult } from 'vue-i18n'
import { useDisplay } from 'vuetify'

import { useSessionStore } from '~/stores/session'
import type { CurrentUser } from '~/types/user'

interface MenuItemData {
  title: TranslateResult
  icon: string
  url: string
}

const i18n = useI18n()
const localePath = useLocalePath()
const sessionStore = useSessionStore()
const auth0 = useAuth0()

const user = computed<CurrentUser | null>(() => sessionStore.user)

const { mobile } = useDisplay()

// memo: computedにしないと、言語を切り替えたときに反映されない
const items = computed<MenuItemData[]>(() => {
  return [
    {
      title: i18n.t('ドロワー.プロフィール'),
      icon: '$mdiAccount',
      url: '/dashboard/profile',
    },
    {
      title: i18n.t('ドロワー.セキュリティ'),
      icon: '$mdiSecurity',
      url: '/dashboard/security',
    },
    {
      title: i18n.t('ドロワー.通知設定'),
      icon: '$mdiBell',
      url: '/dashboard/settings/notifications',
    },
    {
      title: i18n.t('ドロワー.アクセストークン設定'),
      icon: '$mdiApi',
      url: '/dashboard/settings/tokens',
    },
    {
      title: i18n.t('ドロワー.クラウドサイン連携'),
      icon: '$mdiCloud',
      url: '/dashboard/settings/cloudsign',
    },
  ]
})

const logOut = () => {
  sessionStore.logOut(auth0, getBaseUrl() + '/signin')
}
</script>

<style scoped></style>
