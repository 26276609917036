<template>
  <KApp>
    <KSystemBar v-if="isAdminWorkingStatus" color="error">
      <KIcon color="white" icon="$mdiAlert" size="small" />
      <span class="text-body-2 text-white">
        {{
          $t(
            'ダッシュボード.現在テナントの管理者が作業中のため、一時的に申請や承認がブロックされています。作業完了までしばらくお待ち下さい。'
          )
        }}
      </span>
      <KSpacer />
    </KSystemBar>
    <KSystemBar v-else-if="outdatedBrowser" color="warning">
      <KIcon color="white" icon="$mdiAlert" size="small" />
      <KText as="span" class="text-white" variant="body-2">
        {{
          $t(
            'ダッシュボード.お使いのブラウザは動作環境ではありません。ブラウザを最新版にアップデートしてください。'
          )
        }}
      </KText>
      <KSpacer />
    </KSystemBar>
    <KNavigationDrawer
      v-model="drawer"
      :color="isAdminPath ? 'drawerAdmin' : 'drawer'"
      :mobile="mobile"
      :rail="rail"
      @click="rail = false"
    >
      <KList nav>
        <!-- ロゴと開閉ボタン -->
        <KListItem v-if="!mobile">
          <template #title>
            <KRow align="center" justify="center">
              <KCol v-if="!rail">
                <KLink :href="localePath('/dashboard')">
                  <img
                    alt="kickflowロゴ"
                    height="20"
                    src="/images/logo_white.svg"
                  />
                </KLink>
              </KCol>
              <KCol cols="auto">
                <KIcon
                  :icon="
                    rail ? '$mdiArrowCollapseRight' : '$mdiArrowCollapseLeft'
                  "
                  @click.stop="rail = !rail"
                />
              </KCol>
            </KRow>
          </template>
        </KListItem>
      </KList>

      <LayoutDrawerAdminList v-if="isAdminPath" :rail="rail" />
      <LayoutDrawerList v-else />

      <KDivider />

      <KList nav>
        <KListItem
          href="https://support.kickflow.com/hc/ja"
          prepend-icon="$mdiHelpCircle"
          rel="noopener noreferrer"
          target="_blank"
        >
          <template #title>
            <span class="font-weight-bold">
              {{ $t('ヘルプセンター') }}
              <KIcon end icon="$mdiOpenInNew" />
            </span>
          </template>
        </KListItem>
      </KList>
    </KNavigationDrawer>

    <KAppBar @click:nav-icon="drawer = !drawer">
      <KRow align="center">
        <KSpacer />
        <KCol cols="auto">
          <KBadge v-if="notificationCount > 0" :content="notificationBadgeText">
            <KIcon color="grey" icon="$mdiBell" @click="onNotificationClick" />
          </KBadge>
          <KIcon
            v-else
            color="grey"
            icon="$mdiBell"
            @click="onNotificationClick"
          />
        </KCol>
        <KCol v-if="!mobile" cols="auto">
          {{ tenant ? tenant.name : '' }}
        </KCol>
        <KCol cols="auto">
          <LayoutAppBarMenu />
        </KCol>
      </KRow>
    </KAppBar>

    <KMain>
      <KContainer class="pa-0 pa-md-4" fluid>
        <KContainer fluid>
          <PageHeader
            :breadcrumbs="breadcrumbs"
            :plan="plan"
            :subtitle="subtitle"
            :subtitle-html="subtitleHtml"
            :title="title"
          />
        </KContainer>
        <slot />

        <KFooter class="text-caption" color="background">
          <KRow align="center" justify="center">
            <KCol cols="auto">
              <span class="text-no-wrap">© kickflow, Inc.</span>
            </KCol>
            <KCol cols="auto">
              <span>
                <KLink
                  external
                  href="https://support.kickflow.com/hc/ja"
                  target="_blank"
                  >{{ $t('フッター.ヘルプセンター') }}</KLink
                >
              </span>
            </KCol>
            <KCol cols="auto">
              <span class="">
                <KLink
                  external
                  href="https://support.kickflow.com/hc/ja/requests/new"
                  target="_blank"
                  >{{ $t('フッター.お問い合わせ') }}</KLink
                >
              </span>
            </KCol>
            <KCol cols="auto">
              <span class="">
                <KLink external :href="termsUrl" target="_blank">{{
                  $t('フッター.利用規約')
                }}</KLink>
              </span>
            </KCol>
            <KCol cols="auto">
              <span class="text-no-wrap">
                <KLink external :href="privacyUrl" target="_blank">{{
                  $t('フッター.プライバシーポリシー')
                }}</KLink>
              </span>
            </KCol>
            <KCol cols="auto">
              <span class="">
                <KLink
                  external
                  href="https://developer.kickflow.com/"
                  target="_blank"
                  >{{ $t('フッター.開発者向けドキュメント') }}</KLink
                >
              </span>
            </KCol>
            <KCol cols="auto">
              <span class="">
                <KLink
                  external
                  href="https://status.kickflow.com/"
                  target="_blank"
                  >{{ $t('フッター.ステータス') }}</KLink
                >
              </span>
            </KCol>
          </KRow>
        </KFooter>

        <LayoutCommonSnackBar />
        <LayoutProgressSnackbarGroup />
        <ReloadDialog />
      </KContainer>
    </KMain>
  </KApp>
</template>

<script setup lang="ts">
// ログイン後の画面のレイアウト。サブドメインと認証済みであることを要求する
import { useDisplay } from 'vuetify'

import KDivider from '~/components/global/atoms/KDivider.vue'
import { useCommonUiStore } from '~/stores/commonUi'
import { useSessionStore } from '~/stores/session'
import type { CurrentUser } from '~/types/user'

const localePath = useLocalePath()

const drawer = ref(true)
const rail = ref(false)

const route = useRoute()

useHead({
  link: [
    {
      rel: 'canonical',
      href: getCanonicalBaseUrl() + route.path,
    },
  ],
  meta: [
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
})

const context = useNuxtApp()
const consola = useConsola()
const sessionStore = useSessionStore()
const commonUiStore = useCommonUiStore()
const i18n = useI18n()
const router = useRouter()
const firebase = useFirebase()

const { termsPageUrl, privacyPageUrl } = useUrls()
const termsUrl = termsPageUrl()
const privacyUrl = privacyPageUrl()

const user = computed<CurrentUser>(() => sessionStore.user!)
const tenant = computed(() => sessionStore.tenant!)
const notificationCount = computed(() => commonUiStore.notificationCount)
const notificationBadgeText = computed(() => {
  if (notificationCount.value > 0) {
    return notificationCount.value.toString()
  } else {
    return ''
  }
})
const isAdminPath = computed(() => {
  if (route.path.startsWith('/admin')) {
    return true
  }
  return i18n.localeCodes.value.some((lc: string) => {
    return route.path.startsWith(`/${lc}/admin`)
  })
})
const isAdminWorkingStatus = computed(() => {
  return !!tenant.value && !!tenant.value.adminWorkingStatus
})
const title = computed(() => {
  return commonUiStore.header.title
})
const subtitle = computed(() => {
  return commonUiStore.header.subtitle
})
const subtitleHtml = computed(() => {
  return commonUiStore.header.subtitleHtml
})
const breadcrumbs = computed(() => {
  return commonUiStore.header.breadcrumbs
})
const plan = computed(() => {
  return commonUiStore.header.plan
})
const outdatedBrowser = computed(() => {
  return isOutdatedBrowser()
})

const { mobile } = useDisplay()

const onNotificationClick = () => {
  router.push(localePath('/dashboard/notifications'))
}

const showZendesk = () => {
  if (context.$zendesk.isLoaded) {
    context.$zendesk.prefill({
      name: {
        value: user.value.fullName,
        readOnly: true,
      },
      email: {
        value: user.value.email,
        readOnly: true,
      },
    })
    context.$zendesk.show()
  } else {
    context.$zendesk.on('loaded', () => {
      context.$zendesk.prefill({
        name: {
          value: user.value.fullName,
          readOnly: true,
        },
        email: {
          value: user.value.email,
          readOnly: true,
        },
      })
      context.$zendesk.show()
    })
  }
}

const hideZendesk = () => {
  if (context.$zendesk.isLoaded) {
    context.$zendesk.hide()
  } else {
    context.$zendesk.on('loaded', () => {
      context.$zendesk.hide()
    })
  }
}

watch(
  () => isAdminPath.value,
  (val) => {
    if (val) {
      showZendesk()
    } else {
      hideZendesk()
    }
  },
  { immediate: true }
)

const refreshRemoteConfig = async () => {
  consola.debug('Fetch remote config...')
  try {
    await firebase.refreshRemoteConfig()
  } catch (e) {
    consola.debug(e)
  }
  consola.debug('Done!')
}

onMounted(async () => {
  await Promise.all([
    (async () => {
      await commonUiStore.refreshTaskAlert()
    })(),
    (async () => {
      await commonUiStore.refreshNotificationAlert()
    })(),
  ])

  await refreshRemoteConfig()
})
</script>

<style lang="scss" scoped></style>
