/**
 * user agentからブラウザを取得する
 * @param ua
 */
export function getBrowser(ua: string): string {
  if (ua.includes('Opera') || ua.includes('OPR')) {
    return 'Opera'
  }
  if (ua.includes('Edg')) {
    return 'Edge'
  }
  if (ua.includes('Chrome')) {
    return 'Chrome'
  }
  if (ua.includes('Safari')) {
    return 'Safari'
  }
  if (ua.includes('Firefox')) {
    return 'Firefox'
  }
  if (ua.includes('Trident') || ua.includes('MSIE')) {
    return 'IE'
  }
  return 'Unknown'
}

/**
 * user agentからブラウザのバージョンを取得する
 * @param ua
 */
export function getBrowserVersion(ua: string): number {
  const browser = getBrowser(ua)
  if (browser === 'Chrome') {
    return parseFloat(ua.split('Chrome/')[1])
  }
  if (browser === 'Firefox') {
    return parseFloat(ua.split('Firefox/')[1])
  }
  if (browser === 'Safari') {
    return parseFloat(ua.split('Version/')[1])
  }
  if (browser === 'Edge') {
    return parseFloat(ua.split('Edg/')[1])
  }
  if (browser === 'Opera') {
    if (ua.includes('OPR/')) {
      return parseFloat(ua.split('OPR/')[1])
    } else {
      // 古いOpera
      return parseFloat(ua.split('Version/')[1])
    }
  }
  return 0
}

/**
 * Check if the browser is outdated for structuredClone API.
 * Chrome >= 98
 * Firefox >= 94
 * Safari >= 15.4
 * Edge >= 98
 * Opera >= 84
 * ref: https://caniuse.com/?search=structuredClone
 * @returns {boolean} true if the browser is outdated
 */
export function isOutdatedBrowser(): boolean {
  const ua = navigator.userAgent
  const browser = getBrowser(ua)
  const version = getBrowserVersion(ua)

  // 正しくバージョンが取得できなかったときは、未知のブラウザかUserAgentの仕様が変わったときなのでtrueとする
  if (isNaN(version)) {
    return false
  }

  if (browser === 'Chrome' && version < 98) {
    return true
  }
  if (browser === 'Firefox' && version < 94) {
    return true
  }
  if (browser === 'Safari' && version < 15.4) {
    return true
  }
  if (browser === 'Edge' && version < 98) {
    return true
  }
  if (browser === 'Opera' && version < 84) {
    return true
  }
  if (browser === 'IE') {
    return true
  }
  return false
}
