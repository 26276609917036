<template>
  <div>
    <LayoutProgressSnackbar
      v-for="progress in progresses"
      :key="progress.id"
      :progress="progress"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useCommonUiStore } from '~/stores/commonUi'

const commonUiStore = useCommonUiStore()

const progresses = computed(() => {
  return commonUiStore.progresses
})
</script>

<style scoped></style>
