<template>
  <KList nav>
    <KListItem
      v-for="(item, i) in items"
      :key="i"
      :exact="item.exact"
      :prepend-icon="item.icon"
      :to="localePath(item.to)"
    >
      <template #title>
        <span class="font-weight-bold">
          {{ $t(item.title) }}
        </span>
      </template>

      <template #append>
        <KBadge
          v-if="item.to === '/dashboard/tasks' && taskCount > 0"
          :content="taskCount"
          inline
        ></KBadge>
      </template>
    </KListItem>
  </KList>
</template>

<script setup lang="ts">
import { useCommonUiStore } from '~/stores/commonUi'
import { useSessionStore } from '~/stores/session'

interface DrawerItem {
  icon: string
  title: string
  to: string
  exact: boolean
}

const localePath = useLocalePath()
const sessionStore = useSessionStore()
const commonUiStore = useCommonUiStore()

const isAdmin = computed(() => {
  return sessionStore.isAdmin
})
const taskCount = computed(() => {
  return commonUiStore.taskCount
})
const items = computed(() => {
  const items: DrawerItem[] = [
    {
      icon: '$mdiHome',
      title: 'ドロワー.ホーム',
      to: '/dashboard',
      exact: true,
    },
    {
      icon: '$mdiFile',
      title: 'ドロワー.マイチケット',
      to: '/dashboard/mytickets',
      exact: false,
    },
    {
      icon: '$mdiCheckCircle',
      title: 'ドロワー.承認リクエスト',
      to: '/dashboard/tasks',
      exact: false,
    },
    {
      icon: '$mdiMagnify',
      title: 'ドロワー.チケット検索',
      to: '/dashboard/tickets',
      exact: false,
    },
    {
      icon: '$mdiTable',
      title: 'ドロワー.ビュー',
      to: '/dashboard/view',
      exact: false,
    },
  ]
  if (isAdmin.value) {
    items.push({
      icon: '$mdiCog',
      title: 'ドロワー.管理センター',
      to: '/admin',
      exact: false,
    })
  }
  return items
})
</script>

<style scoped></style>
