<template>
  <KSnackbar
    v-if="!!progress"
    :color="snackbarColor"
    location="top right"
    :model-value="snackbar"
    :timeout="-1"
  >
    <div v-if="progress.status === 'pending'">
      <KProgressCircular color="white" indeterminate />
      {{ pendingText }}
    </div>
    <div v-else-if="progress.status === 'in_progress'">
      <KProgressCircular color="white" indeterminate />
      {{ inProgressText }}
    </div>
    <KRow v-else-if="progress.status === 'success'" align="baseline" no-gutters>
      <KCol cols="auto">
        <KIcon icon="$mdiCheckCircle" start />
        {{ successText }}
      </KCol>
      <KSpacer />
      <KCol cols="auto">
        <KBtn
          v-if="outputUrl"
          color="white"
          :href="outputUrl"
          target="_blank"
          variant="outlined"
          @click="onOkClick"
          >ダウンロード</KBtn
        >
        <KBtn v-else color="white" variant="text" @click="onOkClick">OK</KBtn>
      </KCol>
    </KRow>
    <KRow v-else-if="progress.status === 'failure'" align="baseline" no-gutters>
      <KCol cols="auto">
        <KIcon icon="$mdiAlert" start />
        {{ failureText }}
      </KCol>
      <KSpacer />
      <KCol cols="auto">
        <KBtn color="white" variant="text" @click="onOkClick">OK</KBtn>
      </KCol>
    </KRow>
  </KSnackbar>
</template>

<script setup lang="ts">
import { useCommonUiStore } from '~/stores/commonUi'
import type { Progress, ProgressableType } from '~/types/progress'

const props = defineProps<{
  progress: Progress
}>()

const snackbar = ref(true)
const timerId = ref<number | null>(null)

const context = useNuxtApp()
const commonUiStore = useCommonUiStore()

const snackbarColor = computed(() => {
  switch (props.progress.status) {
    case 'pending':
      return 'blue-grey'
    case 'in_progress':
      return 'info'
    case 'success':
      return 'success'
    case 'failure':
      return 'error'
    default:
      return 'info'
  }
})

const pendingText = computed(() => {
  return '準備中...'
})

const inProgressTextMap: Record<ProgressableType, string> = {
  CsvImportEvent: 'インポート中...',
  CsvExportEvent: 'ファイル生成中...',
  OrganizationChartCopyEvent: '組織図をコピーしています...',
  OrganizationChartActivateEvent: '組織図を有効化しています...',
  ExcelExportEvent: 'ファイル生成中...',
  PdfExportEvent: 'ファイル生成中...',
}

const inProgressText = computed(
  () => inProgressTextMap[props.progress.progressableType] ?? ''
)

const successTextMap: Record<ProgressableType, string> = {
  CsvImportEvent: 'インポート完了',
  CsvExportEvent: 'エクスポート完了',
  OrganizationChartCopyEvent: 'コピー完了',
  OrganizationChartActivateEvent: '有効化完了',
  ExcelExportEvent: 'エクスポート完了',
  PdfExportEvent: 'エクスポート完了',
}

const successText = computed(
  () => successTextMap[props.progress.progressableType] ?? ''
)

const failureTextMap: Record<ProgressableType, string> = {
  CsvImportEvent: 'インポート失敗',
  CsvExportEvent: 'エクスポート失敗',
  OrganizationChartCopyEvent: 'コピー失敗',
  OrganizationChartActivateEvent: '有効化失敗',
  ExcelExportEvent: 'エクスポート失敗',
  PdfExportEvent: 'エクスポート失敗',
}

const failureText = computed(() => {
  const text = failureTextMap[props.progress.progressableType]
  return text ? text : ''
})

const outputUrl = computed(() => {
  return props.progress.outputUrl
})

const startWatchingProgress = () => {
  timerId.value = window.setInterval(async () => {
    const newProgress = await context.$kickflowProgressApi.getProgress(
      props.progress.id
    )

    if (newProgress.status === 'success' || newProgress.status === 'failure') {
      stopWatchingProgress()
    }

    commonUiStore.replaceProgressSnackbar(newProgress)
  }, 3000)
}

watch(
  () => props.progress,
  () => {
    if (
      !timerId.value &&
      (props.progress.status === 'pending' ||
        props.progress.status === 'in_progress')
    ) {
      startWatchingProgress()
    }
  },
  { deep: true, immediate: true }
)

const stopWatchingProgress = () => {
  if (timerId.value) {
    window.clearInterval(timerId.value)
    timerId.value = null
  }
}

const onOkClick = () => {
  commonUiStore.removeProgressSnackbar(props.progress.id)
}
</script>

<style scoped></style>
