<template>
  <KSnackbar
    color="info"
    location="top"
    :model-value="reloadDialog"
    :timeout="-1"
  >
    <KIcon icon="$mdiInformation" start />
    {{
      $t(
        '強制アップデート.新しいアプリケーションが配信されています。ページを更新します。'
      )
    }}
    <KBtn color="white" variant="outlined" @click="reload"> OK </KBtn>
  </KSnackbar>
</template>

<script setup lang="ts">
const commonUiStore = useCommonUiStore()

const reloadDialog = computed(() => commonUiStore.reload)

const reload = () => {
  location.reload()
}
</script>

<style scoped></style>
